import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import OrdersReports from "../subcomponents/OrdersReports";

import { useSelector, useDispatch } from "react-redux";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import {
  useHasPermissionInFleet,
  useHasPermissionInAnyFleet,
} from "../customHooks/PermissionHooks";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MissionReports from "../subcomponents/MissionReports";

function AnalyticsTopBar(props) {
  const { fleetList } = useSelector((state) => state.fleetList);
  const [topbar, settopbar] = useState("order-analytics");
  const fleetData = JSON.parse(localStorage.getItem("fleetData"));
  const canSeeLogs = useHasPermissionInAnyFleet("mission-logs");
  const canSeeOrderAnalytics = useHasPermissionInAnyFleet("order-analytics");
  const emailId = localStorage.getItem("useremail");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleanalyticstoggle = (value) => {
    settopbar(value);
  };

  useEffect(() => {
    if (!fleetList || !canSeeOrderAnalytics) {
      if (canSeeLogs) {
        settopbar("mission-logs");
      }
      dispatch(fleetListAndRobotStatusByUserEmail(emailId));
    }
  }, [fleetList, dispatch, emailId, canSeeOrderAnalytics, canSeeLogs]);

  return (
    <>
      <div className="Dashboard_page_rightSide_content_heading">
        <div className="flex items-center justify-center h-full cursor-pointer">
          <p className="FleetView_page_haeding_tag">
            {" "}
            <span
              onClick={() => {
                navigate("/fleetPage");
              }}
            >
              Fleet
            </span>{" "}
            <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
            <span className="FleetView_page_Heading_fleetName">
              {props.fleetData
                ? props.fleetData.fleetName
                : fleetData.fleetName}
            </span>
          </p>
        </div>
        <div>
          <div className="FleetPage_top_bar_left_side">
            {canSeeLogs && (
              <div
                className={topbar === "mission-logs" ? "top_bar_active" : ""}
                onClick={() => handleanalyticstoggle("mission-logs")}
              >
                {" "}
                Mission Logs
              </div>
            )}
            {canSeeOrderAnalytics && (
              <div
                className={topbar === "order-analytics" ? "top_bar_active" : ""}
                onClick={() => handleanalyticstoggle("order-analytics")}
              >
                {" "}
                Order Analytics
              </div>
            )}
          </div>
          <Dropdown
            onSelect={(eventKey) => {
              handleanalyticstoggle(eventKey);
            }}
            id="menu_dropdown_select"
          >
            <Dropdown.Toggle id="dropdown-basic">{"Analytics"}</Dropdown.Toggle>
            <Dropdown.Menu className="custom-dropdown-menu">
              {canSeeLogs && (
                <Dropdown.Item key={"mission-logs"} eventKey={"mission-logs"}>
                  Mission Logs
                </Dropdown.Item>
              )}
              {canSeeOrderAnalytics && (
                <Dropdown.Item
                  key={"order-analytics"}
                  eventKey={"order-analytics"}
                >
                  Order Analytics
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {topbar === "mission-logs" ? (
        <MissionReports
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : topbar === "order-analytics" ? (
        <OrdersReports
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : null}
    </>
  );
}

export default AnalyticsTopBar;
